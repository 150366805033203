#cart-icon {
    color: white;
    font-size: 9px;
}

.modal {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2rem;
    background-color: #224229;
    color: white;
}

.cart-img {
    width: 8rem;
}

.item-div {
    display: flex;
    gap: 1rem;
    border-top: 1px solid white;
    padding: 1rem 0;
}

.item-div-empty {
    display: flex;
    justify-content: center;
    gap: 1rem;
    border-top: 1px solid white;
    padding: 1rem 0;
    width: 15rem;
}

.cart-item-change {
    display: flex;
    gap: 1rem;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.item-right-div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 15rem;
}

.cart-item-header {
    display: flex;
    justify-content: space-between;
}

.products-div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 100%;
    overflow-y: scroll;
    padding: .5rem;
}

.products-div::-webkit-scrollbar {
    width: 0.5rem;
    /* Width of the scrollbar */
    background-color: white;
    border-radius: 50px;
    /* Background color of the scrollbar track */
}

.products-div::-webkit-scrollbar-thumb {
    background-color: #589165;
    /* Color of the scrollbar thumb */
    border-radius: 50px;
    height: 10px;
    /* Rounded shape for the scrollbar thumb */
}

.products-div::-webkit-scrollbar-track {
    background-color: #ffffff;
    border-radius: 50px;
    /* Background color of the scrollbar track */
}

.products-div::-webkit-scrollbar-button {
    display: none;
    /* Hide the scrollbar buttons (arrows) */
}

.products-div::-webkit-scrollbar-track-piece {
    height: .2rem;
}

.input-cart-small {
    border-radius: 35px;
    width: 5rem;
    height: 2rem;
    border: 1px solid #DEE0D7;
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.bottom-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
}

.price-div {
    display: flex;
    justify-content: space-between;
    margin: 1rem 0;
    width: 100%;
}

.subtotal-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.cart-button-checkout {
    width: 100%;
    height: 57px;
    border-radius: 35px;
    border: none;
    color: #224229;
    background-color: white;
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    cursor: pointer;
}

.cart-title {
    font-family: "Lustria", serif;
    font-size: 26px;
}

.cart-item-name {
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    font-weight: 500;
}

.cart-remove {
    font-family: "Raleway", sans-serif;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
}

#modal.modal-right {
    justify-content: flex-end;
}

#modal-content {
    transition: transform 0.4s ease-in-out;
    /* Added transition property */
}

#modal-content.active {
    /* Slide in from the right */
    animation: slideIn 0.4s ease-in-out;
}

.column {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.fa-xmark {
    margin-right: 1rem;
    cursor: pointer;
}

/* Add animation when the modal-right becomes active */
@keyframes slideIn {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0);
    }
}

@keyframes slideOut {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(100%);
    }
}



.empty-cart-title {
    font-family: "Raleway", sans-serif;
    color: white;
    margin-top: 2rem;
    text-align: center;
}

.top-wrapper {
    height: 55vh;
}



@media (max-width: 580px) {
    .item-right-div {
        width: 10rem;
    }
}
