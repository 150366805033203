.profile-dropdown {
  position: absolute;
}

.hidden {
  display: none;
}

.green-top {
  height: 2rem;
  background-color: #224229;
}


.site-name {
  text-decoration: none;
  font-size: 32px;
  font-family: 'Raleway', sans-serif;
  color: #224229;
  font-weight: 500;
}

.nav-bar {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background-color: #FCF9F3;
}

.mid-navbar {
  display: flex;
  font-family: 'Raleway', sans-serif;
  font-size: 14px;
  color: #224229;
  gap: 3rem;
  font-weight: 600;
}

.profile-button {
  border: none;
  background-color: rgba(255, 255, 255, 0);
}

.fa-user {
  font-size: 18px;
  color: #224229;
  background-color: none;
}

.fa-basket-shopping-simple {
  font-size: 18px;
  color: #224229;
  background-color: none;
  cursor: pointer;
}

.right-nav-buttons {
  display: flex;
  gap: .5rem;
}


.mid-navbar p {
  cursor: pointer;
}

.nav-button {
  position: relative;
  padding: .2rem 0;
  box-sizing: border-box;
  overflow: hidden;
  transition: border-bottom-color 0.3s ease-in-out;
}

.nav-button::before,
.nav-button::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 0;
  height: 1px;
  background-color: #224229;
  transition: width 0.3s ease-in-out;
}

.nav-button::before {
  left: 50%;
  transform: translateX(-50%);
}

.nav-button::after {
  right: 50%;
  transform: translateX(50%);
}

.nav-button:hover::before,
.nav-button:hover::after {
  width: 100%;
  transition-delay: 0.05s;
}

@media(max-width: 840px) {
  .nav-hidden {
    display: none;
  }
}
