.order-logo {
    font-family: "Raleway", sans-serif;
    font-size: 14px;
    color: #224229ab;
    font-weight: 500;
}

.order-title {
    font-family: "Lustria", serif;
    width: 100%;
    text-align: center;
    color: #224229;
    font-size: 2rem;
    font-weight: 100;
    margin-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #2242293a;
}

.order-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    border-radius: 6px;
    padding: 1rem 3rem 2rem 3rem;
    grid-gap: 1rem;
    gap: 1rem;
    background-color: #FCF9F3;
}
