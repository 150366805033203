@import url(https://fonts.googleapis.com/css2?family=Lustria&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);
#modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 90;
}

#modal-background {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

#modal-content {
  position: absolute;
  background-color: white;
}



.image-container {
    width: 100%;
    position: relative;
    overflow: hidden;
    height: 100%;
}

.splash-bg-image {
    width: 100%;
}

.splash-title {
    font-family: "lustria", serif;
    font-size: 3.2rem;
    color: #FCF9F3;

}

.splash-message {

    font-family: 'Raleway', sans-serif;
    font-size: 22px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 1rem;
    gap: 1rem;
    position: absolute;
    align-content: center;
    width: 100%;
    top: 7rem;
    width: 100%;
    height: auto;
    color: #FCF9F3;
}


.splash-button {
    width: 170px;
    height: 52px;
    background-color: #F6CFB2;
    border-radius: 35px;
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    border: none;
    color: #224229;
    font-weight: 600;
    cursor: pointer;
}

.splash-button:hover {
    color: #FCF9F3;
    background-color: #224229;
}

@media (max-width: 840px) {
    .splash-title {
        font-size: 2rem;
    }
}

.plant-index-title {
    font-family: "Lustria", sans-serif;
    font-size: 40px;
    color: #224229;
    padding: 1rem;
    font-weight: 200;
}

.all-plant-cards {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 2rem 1.8rem;
    gap: 2rem 1.8rem;
    justify-content: space-around;
    background-color: #FCF9F3;
    padding-bottom: 1rem;
    padding: 1rem;

}

.plant-index-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0 10rem;
}

.plant-card {
    width: 15rem;
}

.plant-index-img {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    border-radius: 3px;
}

.plant-index-img:hover {
    height: 101%;
    width: 101%;
    box-sizing: border-box;
}

.plant-index-card {
    /* width: 5rem;
    height: 5rem; */

    overflow: hidden;
}

.img-container {
    width: 15rem;
    height: 23rem;
    position: relative;

}



.plant-index-price {
    font-family: "Lustria", serif;
    color: #0E6938;
    text-decoration: none;
}

.plant-index-username {
    font-family: "Raleway", sans-serif;
    color: #5F7662;
    text-decoration: none;
    font-size: 11px;
    text-align: right;
}


.plant-index-name {
    font-family: "Lustria", serif;
    font-size: 16px;
    color: #0E6938;
    text-decoration: none;

}


.plant-index-top {
    display: flex;
    justify-content: space-between;
    padding-top: .5rem;
}


.fa-heart {
    visibility: hidden;
    position: absolute;
    top: 1rem;
    left: 1rem;
    color: #224229;
    cursor: pointer;
}

.plant-card:hover .fa-heart {
    visibility: visible;

}

.fade-in {
    opacity: 0;
    -webkit-animation: fade-in 1s ease-in-out forwards;
            animation: fade-in 1s ease-in-out forwards;
    transition: opacity 2s ease;
    /* Add transition property */
}

@-webkit-keyframes fade-in {
    0% {
        opacity: 0;
        -webkit-transform: translateY(20px);
                transform: translateY(20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
        -webkit-transform: translateY(20px);
                transform: translateY(20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
}

.order-logo {
    font-family: "Raleway", sans-serif;
    font-size: 14px;
    color: #224229ab;
    font-weight: 500;
}

.order-title {
    font-family: "Lustria", serif;
    width: 100%;
    text-align: center;
    color: #224229;
    font-size: 2rem;
    font-weight: 100;
    margin-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #2242293a;
}

.order-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    border-radius: 6px;
    padding: 1rem 3rem 2rem 3rem;
    grid-gap: 1rem;
    grid-gap: 1rem;
    gap: 1rem;
    background-color: #FCF9F3;
}

#cart-icon {
    color: white;
    font-size: 9px;
}

.modal {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2rem;
    background-color: #224229;
    color: white;
}

.cart-img {
    width: 8rem;
}

.item-div {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    border-top: 1px solid white;
    padding: 1rem 0;
}

.item-div-empty {
    display: flex;
    justify-content: center;
    grid-gap: 1rem;
    gap: 1rem;
    border-top: 1px solid white;
    padding: 1rem 0;
    width: 15rem;
}

.cart-item-change {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.item-right-div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 15rem;
}

.cart-item-header {
    display: flex;
    justify-content: space-between;
}

.products-div {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
    height: 100%;
    overflow-y: scroll;
    padding: .5rem;
}

.products-div::-webkit-scrollbar {
    width: 0.5rem;
    /* Width of the scrollbar */
    background-color: white;
    border-radius: 50px;
    /* Background color of the scrollbar track */
}

.products-div::-webkit-scrollbar-thumb {
    background-color: #589165;
    /* Color of the scrollbar thumb */
    border-radius: 50px;
    height: 10px;
    /* Rounded shape for the scrollbar thumb */
}

.products-div::-webkit-scrollbar-track {
    background-color: #ffffff;
    border-radius: 50px;
    /* Background color of the scrollbar track */
}

.products-div::-webkit-scrollbar-button {
    display: none;
    /* Hide the scrollbar buttons (arrows) */
}

.products-div::-webkit-scrollbar-track-piece {
    height: .2rem;
}

.input-cart-small {
    border-radius: 35px;
    width: 5rem;
    height: 2rem;
    border: 1px solid #DEE0D7;
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.bottom-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
}

.price-div {
    display: flex;
    justify-content: space-between;
    margin: 1rem 0;
    width: 100%;
}

.subtotal-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.cart-button-checkout {
    width: 100%;
    height: 57px;
    border-radius: 35px;
    border: none;
    color: #224229;
    background-color: white;
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    cursor: pointer;
}

.cart-title {
    font-family: "Lustria", serif;
    font-size: 26px;
}

.cart-item-name {
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    font-weight: 500;
}

.cart-remove {
    font-family: "Raleway", sans-serif;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
}

#modal.modal-right {
    justify-content: flex-end;
}

#modal-content {
    transition: -webkit-transform 0.4s ease-in-out;
    transition: transform 0.4s ease-in-out;
    transition: transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
    /* Added transition property */
}

#modal-content.active {
    /* Slide in from the right */
    -webkit-animation: slideIn 0.4s ease-in-out;
            animation: slideIn 0.4s ease-in-out;
}

.column {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.fa-xmark {
    margin-right: 1rem;
    cursor: pointer;
}

/* Add animation when the modal-right becomes active */
@-webkit-keyframes slideIn {
    from {
        -webkit-transform: translateX(100%);
                transform: translateX(100%);
    }

    to {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}
@keyframes slideIn {
    from {
        -webkit-transform: translateX(100%);
                transform: translateX(100%);
    }

    to {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}

@-webkit-keyframes slideOut {
    from {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }

    to {
        -webkit-transform: translateX(100%);
                transform: translateX(100%);
    }
}

@keyframes slideOut {
    from {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }

    to {
        -webkit-transform: translateX(100%);
                transform: translateX(100%);
    }
}



.empty-cart-title {
    font-family: "Raleway", sans-serif;
    color: white;
    margin-top: 2rem;
    text-align: center;
}

.top-wrapper {
    height: 55vh;
}



@media (max-width: 580px) {
    .item-right-div {
        width: 10rem;
    }
}

.image-carousel .carousel-slider {}

.image-carousel .carousel-status {
    display: none;
}

.image-carousel .carousel .thumb.selected {
    border: 1px solid #224229;
}


/* .image-carousel .carousel-root {
    display: flex;

} */

.image-carousel .carousel {
    display: flex;
    flex-direction: column;
}

.wrapper {
    padding: 1rem;
    background-color: #FCF9F3;
    margin: 0 1rem;
}

.plant-detail-wrapper {
    display: flex;

}

.plant-detail-top {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    height: 4rem;
    border-top: 1px solid #D9DBD2;
    border-bottom: 1px solid #D9DBD2;
    margin-bottom: 1rem;
    align-items: center;

}

.plant-detail-header {
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    color: #224229;
    font-weight: 500;
    cursor: pointer;
}

.plant-detail-name {
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    color: #224229;
    font-weight: 500;
}

.plant-detail-heading-division {
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    color: #224229;
    font-weight: 500;
}

.plant-detail-bottom {
    display: flex;
    justify-content: space-around;
}

.plant-detail-pic-div {
    width: 40%;
    position: relative;
}

.plant-detail-main-pic {
    overflow: hidden;
}

.img {
    width: 10rem;
    height: 10rem;
}

.plant-detail-main-pic img {
    width: 100%;
}

.plant-detail-info {
    width: 50%;
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
}


/* inside the details side of the page */

.plant-detail-subheader {
    display: flex;
    justify-content: space-between;
}

.side-title {
    font-family: "Lustria", serif;
    font-size: 24px;
    font-weight: 400;
    color: #224229;
}

.price {
    font-family: "Lustria", serif;
    font-size: 24px;
    font-weight: 400;
    color: #224229;
}

.plant-detail-description {
    font-family: "Raleway", sans-serif;
    font-size: 15px;
    color: #224229;
}

.plant-detail-pot-colors {
    display: flex;
    grid-gap: 2rem;
    gap: 2rem;
    font-size: 12px;
    font-family: "Raleway", sans-serif;
    font-weight: 500;
}


.make-colors-small {
    width: 10rem;
}

.stone {
    width: 42px;
    height: 42px;
    background-color: #E6E8EA;
    border-radius: 35px;
}

.stone:hover {
    cursor: pointer;
    box-shadow: inset 0 0 1px rgba(0, 0, 0, .4), 0 0 0 2px #fcf9f3, 0 0 0 4px #22422957;
}

.plant-detail-stone.selected .stone {
    box-shadow: inset 0 0 1px rgba(0, 0, 0, .4), 0 0 0 2px #fcf9f3, 0 0 0 4px #224229;
}

.charcoal {
    width: 42px;
    height: 42px;
    background-color: #3B4037;
    border-radius: 35px;
}

.charcoal:hover {
    cursor: pointer;
    box-shadow: inset 0 0 1px rgba(0, 0, 0, .4), 0 0 0 2px #fcf9f3, 0 0 0 4px #22422957;
}

.plant-detail-charcoal.selected .charcoal {
    box-shadow: inset 0 0 1px rgba(0, 0, 0, .4), 0 0 0 2px #fcf9f3, 0 0 0 4px #224229;
}


.slate {
    width: 42px;
    height: 42px;
    background-color: #B7C7CD;
    border-radius: 35px;
}

.slate:hover {
    cursor: pointer;
    box-shadow: inset 0 0 1px rgba(0, 0, 0, .4), 0 0 0 2px #fcf9f3, 0 0 0 4px #22422957;
}

.plant-detail-slate.selected .slate {
    box-shadow: inset 0 0 1px rgba(0, 0, 0, .4), 0 0 0 2px #fcf9f3, 0 0 0 4px #224229;
}

.plant-detail-stone {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    grid-gap: .5rem;
    gap: .5rem;
}

.plant-detail-charcoal {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    grid-gap: .5rem;
    gap: .5rem;
}


.plant-detail-slate {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    grid-gap: .5rem;
    gap: .5rem;
}

.plant-detail-buttons {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
}

.cart-button {
    width: 287px;
    height: 57px;
    border-radius: 35px;
    border: none;
    background-color: #224229;
    color: white;
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    cursor: pointer;

}

.input-cart {
    border-radius: 35px;
    width: 100px;
    height: 57px;
    border: 1px solid #DEE0D7;
    font-family: "Raleway", sans-serif;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.fa-minus {
    font-size: 14px;
    color: #224229;
    cursor: pointer;
}

.fa-plus {
    font-size: 14px;
    color: #224229;
    cursor: pointer;
}

.num {
    padding-bottom: .2rem;
}

.cart-button-login {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 287px;
    height: 57px;
    border-radius: 35px;
    border: none;
    background-color: #224229;
    color: white;
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    cursor: pointer;

}

.plant-detail-pic-div:hover .fa-heart {
    visibility: visible;
}

.pot-colors {
    font-family: "Raleway", sans-serif;
    font-size: 11px;
    font-weight: 500;
    grid-area: color;
    letter-spacing: .1em;
    padding-top: 8px;
    text-transform: uppercase;
    margin-bottom: .5rem;
}

.heart-filled {
    color: #2242299c;
    background-color: #2242299c;
}

@media screen and (min-width: 922px) {
    .wrapper {
        margin-left: calc(2rem + ((100vw - 922px) / 4));
        /* Increase margin-left by 2rem for every 4px increase in screen width */
        margin-right: calc(2rem + ((100vw - 922px) / 4));
    }
}

@media (max-width: 940px) {}

@media screen and (max-width: 840px) {

    .plant-detail-bottom {
        flex-direction: column;
        align-items: center;
        grid-gap: .5rem;
        gap: .5rem;
    }

    .input-cart {
        font-size: 15px;
        height: 40px;
    }

    .fa-minus {
        font-size: 10px;
    }

    .fa-plus {
        font-size: 10px;
    }

    .cart-button {
        height: 40px;
    }
}

.input-file {
    width: 100%;
    margin: 0.5rem 0 1rem 0;

}

input #file-upload-button {
    width: 69px;
    height: 19px;
    background-color: #F6CFB2;
    border: none;
    border-radius: 6px;
}

.input-file {
    display: none;
}

.file-button {
    width: 100%;
    height: 6rem;
    border: 1px dashed #F6CFB2;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
    cursor: pointer;
}

.fa-cloud-arrow-up {
    font-size: 3rem;
    color: #F6CFB2;
}

.file-check {
    display: flex;
    justify-content: space-between;
}

#no-green {
    color: #f6d0b200;
    width: 100%;
    margin: .5rem 0 1rem 0;
    height: 2rem;
}

#no-green::focus {
    border: none;
    outline: none;
}

#invisible {
    position: absolute;
    visibility: hidden;
    scale: 0;
}

#visible {
    position: relative;
    visibility: visible;
    margin-left: 1rem;
}

.confirm-delete-div {
    background-color: #FCF9F3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-gap: 2.5rem;
    gap: 2.5rem;
    margin: 1.5rem 3rem;
    height: 10rem;
    width: 20rem;
    border-radius: 6px;
    align-items: center;
}

.del-title {
    font-family: "Lustria", serif;
    font-size: 16px;
    color: #224229;
    font-weight: 500;
    padding-bottom: 1rem;
    border-bottom: 1px solid #224229;
}

.button-div {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
}

.not-confirm {
    border-radius: 25px;
    background-color: #224229;
    color: #FCF9F3;
    font-family: "Raleway", sans-serif;
    font-size: 12px;
    font-weight: bold;
    border: none;
    height: 2.5rem;
    width: 8rem;
    padding: .5rem;
    cursor: pointer;

}

.confirm {

    border-radius: 25px;
    background-color: #FCF9F3;
    color: #224229;
    font-family: "Raleway", sans-serif;
    font-size: 12px;
    font-weight: bold;
    border: 2px solid #224229;
    height: 2.5rem;
    width: 8rem;
    padding: .5rem;
    cursor: pointer;
}

.edit-button {
    border: none;
    background-color: #F6CFB2;
    color: #0E6938;
    border-radius: 6px;
    font-family: "Lustria", sans;
    width: 69px;
    height: 19px;
    font-size: 11px;
    cursor: pointer;
}

.delete-button {
    border: 1px solid #F6CFB2;
    color: #0E6938;
    background-color: rgba(255, 255, 255, 0);
    border-radius: 6px;
    font-family: "Lustria", sans;
    width: 69px;
    height: 19px;
    font-size: 11px;
    cursor: pointer;
}

.change-buttons {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    padding-top: .5rem;

}

.review-label {
    display: flex;
    flex-direction: column;
}

.rating-input {
    display: flex;
    justify-content: center;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
    margin: 10px 0;

}

.rating-input div {
    cursor: pointer;
}

.review-form {
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    padding: 1rem 3rem 2rem 3rem;
    grid-gap: 1rem;
    gap: 1rem;
    width: 30rem;
}

#modal-content {
    border-radius: 6px;
}

.review-label {
    width: 100%;
}


.form-dropdown {
    width: 100%;
    border: 1px solid #EDEBE6;
    background: white;
    margin: .2rem 0 .5rem 0;
    font-family: "Raleway", sans-serif;
    font-size: 11px;
}

.form-dropdown:focus {
    outline: 2px solid #17702a;
    text-decoration: none;
}

.review-input {
    width: 100%;
    height: 3.8rem;
    margin: .2rem 0 .5rem 0;
    border: 1px solid #EDEBE6;
    background: white;
    color: #224229;
    padding: 0 1rem;
    font-family: "Raleway", sans-serif;
    font-size: 11px;
    padding: .5rem;
}

.review-input:focus {
    outline: 2px solid #17702a;
}

.review-button {
    width: 100%;
    height: 2.5rem;
    border-radius: 25px;
    border: none;
    background-color: #224229;
    font-size: 14px;
    font-weight: 500;
    color: white;
    cursor: pointer;
    margin-top: 1rem;
    font-family: "Raleway", sans-serif;
}

.review-title {
    font-family: 'Lustria', serif;
    color: #224229;
    font-size: 22px;
    margin: 1rem 0;
}

.stars-title {
    font-family: 'Raleway', sans-serif;
    font-size: 11px;
    color: #224229;
    font-weight: 500;
}



.confirm-delete-div {
    background-color: #FCF9F3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-gap: 2.5rem;
    gap: 2.5rem;
    margin: 1.5rem 3rem;
    height: 10rem;
    width: 20rem;
    border-radius: 6px;
    align-items: center;
}

.del-title {
    font-family: "Lustria", serif;
    font-size: 16px;
    color: #224229;
    font-weight: 500;
    padding-bottom: 1rem;
    border-bottom: 1px solid #224229;
}

.button-div {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
}

.not-confirm {
    border-radius: 25px;
    background-color: #224229;
    color: #FCF9F3;
    font-family: "Raleway", sans-serif;
    font-size: 12px;
    font-weight: bold;
    border: none;
    height: 2.5rem;
    width: 8rem;
    padding: .5rem;
    cursor: pointer;

}

.confirm {

    border-radius: 25px;
    background-color: #FCF9F3;
    color: #224229;
    font-family: "Raleway", sans-serif;
    font-size: 12px;
    font-weight: bold;
    border: 2px solid #224229;
    height: 2.5rem;
    width: 8rem;
    padding: .5rem;
    cursor: pointer;
}

.review-index-title {
    font-family: "Lustria", serif;
    font-family: 48px;
    color: #224229;
    padding-bottom: 1rem;
    font-weight: 200;
}

.review-card {
    width: 100%;
    padding: 1rem 0 5rem 0;
    border-top: 1px solid rgba(175, 175, 175, 0.356);

}

.reviews-index-container {
    display: flex;
    background-color: #FCF9F3;
    flex-direction: column;
    justify-content: center;
    padding: 1rem 10rem;
}

.review-index-card {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    overflow: hidden;
}

.review-img-container {
    width: 8rem;

}

.review-index-img {
    width: 8rem;
    box-sizing: border-box;
    padding-top: 1rem;
}

.all-reviews-cards {
    display: flex;
    flex-direction: column;

}

.top-right-review {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.user-icon {
    font-family: "Raleway", sans-serif;
    font-size: 22px;
    font-weight: 500;
    background-color: #F6CFB2;
    border-radius: 35px;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.fa-star {
    color: #2242299c;
}

.user-name {
    font-family: "Raleway", sans-serif;
    font-size: 12px;
    font-weight: 600;
    color: #224229;
    padding-bottom: .3rem;
}

.time-created {
    color: #6A6C77;
    font-family: "Raleway", sans-serif;
    font-size: 11px;

}

.review-text {
    font-family: "Raleway", sans-serif;
    font-size: 12px;
    color: #224229;
}

.review-right {
    width: 100%;
    display: flex;
    flex-direction: column;
}


.top-info-left {
    display: flex;
    flex-direction: column;
}

.bottom-right-review {
    padding: 1rem 0;
}

.plant-name-review {
    font-family: "Raleway", sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: #224229;
    padding-bottom: .3rem;
}


.small-button {
    width: 9rem;
    height: 2.5rem;
    background-color: #224229;
    color: white;
    font-family: "Raleway", sans-serif;
    font-size: 14px;
    font-weight: 500;
    border-radius: 35px;
    border: none;
    cursor: pointer;

}

.review-title-div {
    display: flex;
    justify-content: space-between;
}

.change-buttons {
    display: flex;
    justify-content: flex-end;
}



.fav-empty-title {
    font-family: "Raleway", sans-serif;
    color: #224229;
}

.user-wrapper {
    display: flex;
    height: 100%;
    background-color: #FCF9F3;
    padding: 4rem;
    justify-content: center;
    grid-gap: 1rem;
    gap: 1rem
}

.logout-button {
    cursor: pointer;
}

.side-bar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-start;
    width: 8rem;
    grid-gap: 1rem;
    gap: 1rem;
}


.sell-text {
    font-family: "Raleway", sans-serif;
    font-size: 14px;
    color: #224229;
    text-decoration: none;
}

.logout-button {
    border: none;
    background-color: rgba(255, 255, 255, 0);
    font-family: "Raleway", sans-serif;
    font-size: 14px;
    color: #224229;
    text-decoration: none;
    padding: 0;
}

.account-side-bar {
    display: flex;
    justify-content: space-between;
    width: 100%;
    color: #22422986;
}

.profile-dropdown {
  position: absolute;
}

.hidden {
  display: none;
}

.green-top {
  height: 2rem;
  background-color: #224229;
}


.site-name {
  text-decoration: none;
  font-size: 32px;
  font-family: 'Raleway', sans-serif;
  color: #224229;
  font-weight: 500;
}

.nav-bar {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background-color: #FCF9F3;
}

.mid-navbar {
  display: flex;
  font-family: 'Raleway', sans-serif;
  font-size: 14px;
  color: #224229;
  grid-gap: 3rem;
  gap: 3rem;
  font-weight: 600;
}

.profile-button {
  border: none;
  background-color: rgba(255, 255, 255, 0);
}

.fa-user {
  font-size: 18px;
  color: #224229;
  background-color: none;
}

.fa-basket-shopping-simple {
  font-size: 18px;
  color: #224229;
  background-color: none;
  cursor: pointer;
}

.right-nav-buttons {
  display: flex;
  grid-gap: .5rem;
  gap: .5rem;
}


.mid-navbar p {
  cursor: pointer;
}

.nav-button {
  position: relative;
  padding: .2rem 0;
  box-sizing: border-box;
  overflow: hidden;
  transition: border-bottom-color 0.3s ease-in-out;
}

.nav-button::before,
.nav-button::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 0;
  height: 1px;
  background-color: #224229;
  transition: width 0.3s ease-in-out;
}

.nav-button::before {
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.nav-button::after {
  right: 50%;
  -webkit-transform: translateX(50%);
          transform: translateX(50%);
}

.nav-button:hover::before,
.nav-button:hover::after {
  width: 100%;
  transition-delay: 0.05s;
}

@media(max-width: 840px) {
  .nav-hidden {
    display: none;
  }
}

/* TODO Add site wide styles */

* {
    margin: 0;
    box-sizing: border-box;
    caret-color: transparent
}

#disabled {
    background-color: #22422971;
    cursor: default;
}

.form-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FCF9F3;
    height: 100%;
}

.form-container {
    display: flex;
    flex-direction: column;
    width: 28rem;
}


.form-title {
    font-family: 'Lustria', serif;
    color: #224229;
    font-size: 32px;
    margin: 1rem 0;
}

.form-label {
    font-family: 'Raleway', sans-serif;
    font-size: 11px;
    color: #224229;
    font-weight: 500;

}

.form-input {
    width: 100%;
    height: 3.8rem;
    margin: .5rem 0 1rem 0;
    border: 1px solid #EDEBE6;
    background: white;
    color: #224229;
    padding: .5rem;
    font-family: "Raleway", sans-serif;
    font-size: 11px;
}

.form-input:focus {
    outline: 2px solid #17702a;

    /* Set the outline color to green when the input is focused */
}


.form-button {
    width: 100%;
    height: 3.5rem;
    border-radius: 25px;
    border: none;
    background-color: #224229;
    font-size: 14px;
    font-weight: bold;
    color: white;
    cursor: pointer;
    margin-top: 1rem;
}

.session-nav {
    display: flex;
    width: 28rem;
    justify-content: space-around;

    border-bottom: 1px solid #224229;
}

.login-nav {
    font-family: 'Raleway', sans-serif;
    font-size: 15px;
    color: #224229;
}

.signup-nav {
    font-family: 'Raleway', sans-serif;
    font-size: 15px;
    color: #224229;
    text-decoration: none;
}

.login-div {
    width: 50%;
    height: 100%;
    text-align: center;
    padding: 1rem 0;
    color: #224229;
    cursor: pointer;
}

.signup-div {
    width: 50%;
    height: 100%;
    text-align: center;
    padding: 1rem 0;
    color: #224229;
    cursor: pointer;
}

.session-nav .active {
    font-weight: bold;
    border-bottom: 2px solid #224229;
}

a {
    text-decoration: none;
}

a::after {
    text-decoration: none;
}

.footer {
    width: 100%;
    height: 5rem;
    /* position: fixed;
    bottom: 0; */
    background-color: #224229;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
}

.developer-name {
    font-family: 'Raleway', sans-serif;
    font-size: 12px;
    color: white;
}

.social-icon {
    width: 25px;
}

.social-icon:hover {
    width: 28px;
}

.social-media-div {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
}

.root {
    display: flex;
    justify-content: flex-end;

}

#root {
    display: flex;
    flex-direction: column;
    background-color: #FCF9F3;
}


html,
#root,
body {
    height: 100%;
    background-color: #FCF9F3;
}



.errors {
    color: rgb(182, 70, 70);
    font-family: "Raleway", sans-serif;
    font-size: 10px;
    text-decoration: none;
    list-style: none;
}

.demo-button {
    width: 100%;
    height: 3.5rem;
    border-radius: 25px;
    border: none;
    background-color: #22422900;
    border: 2px solid #224229;
    font-size: 14px;
    font-weight: bold;
    color: #224229;
    cursor: pointer;
    margin-top: 1rem;
}


.form-radio-buttons {
    display: flex;
    justify-content: space-around;
}

.center-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
}

.not-found {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 35rem;
    grid-gap: 2rem;
    gap: 2rem;
}

.wrapper-lost {
    display: flex;
    justify-content: center;
}

