.plant-index-title {
    font-family: "Lustria", sans-serif;
    font-size: 40px;
    color: #224229;
    padding: 1rem;
    font-weight: 200;
}

.all-plant-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem 1.8rem;
    justify-content: space-around;
    background-color: #FCF9F3;
    padding-bottom: 1rem;
    padding: 1rem;

}

.plant-index-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0 10rem;
}

.plant-card {
    width: 15rem;
}

.plant-index-img {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    border-radius: 3px;
}

.plant-index-img:hover {
    height: 101%;
    width: 101%;
    box-sizing: border-box;
}

.plant-index-card {
    /* width: 5rem;
    height: 5rem; */

    overflow: hidden;
}

.img-container {
    width: 15rem;
    height: 23rem;
    position: relative;

}



.plant-index-price {
    font-family: "Lustria", serif;
    color: #0E6938;
    text-decoration: none;
}

.plant-index-username {
    font-family: "Raleway", sans-serif;
    color: #5F7662;
    text-decoration: none;
    font-size: 11px;
    text-align: right;
}


.plant-index-name {
    font-family: "Lustria", serif;
    font-size: 16px;
    color: #0E6938;
    text-decoration: none;

}


.plant-index-top {
    display: flex;
    justify-content: space-between;
    padding-top: .5rem;
}


.fa-heart {
    visibility: hidden;
    position: absolute;
    top: 1rem;
    left: 1rem;
    color: #224229;
    cursor: pointer;
}

.plant-card:hover .fa-heart {
    visibility: visible;

}

.fade-in {
    opacity: 0;
    animation: fade-in 1s ease-in-out forwards;
    transition: opacity 2s ease;
    /* Add transition property */
}

@keyframes fade-in {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
