.image-container {
    width: 100%;
    position: relative;
    overflow: hidden;
    height: 100%;
}

.splash-bg-image {
    width: 100%;
}

.splash-title {
    font-family: "lustria", serif;
    font-size: 3.2rem;
    color: #FCF9F3;

}

.splash-message {

    font-family: 'Raleway', sans-serif;
    font-size: 22px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    position: absolute;
    align-content: center;
    width: 100%;
    top: 7rem;
    width: 100%;
    height: auto;
    color: #FCF9F3;
}


.splash-button {
    width: 170px;
    height: 52px;
    background-color: #F6CFB2;
    border-radius: 35px;
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    border: none;
    color: #224229;
    font-weight: 600;
    cursor: pointer;
}

.splash-button:hover {
    color: #FCF9F3;
    background-color: #224229;
}

@media (max-width: 840px) {
    .splash-title {
        font-size: 2rem;
    }
}
