.image-carousel .carousel-slider {}

.image-carousel .carousel-status {
    display: none;
}

.image-carousel .carousel .thumb.selected {
    border: 1px solid #224229;
}


/* .image-carousel .carousel-root {
    display: flex;

} */

.image-carousel .carousel {
    display: flex;
    flex-direction: column;
}

.wrapper {
    padding: 1rem;
    background-color: #FCF9F3;
    margin: 0 1rem;
}

.plant-detail-wrapper {
    display: flex;

}

.plant-detail-top {
    display: flex;
    gap: 1rem;
    height: 4rem;
    border-top: 1px solid #D9DBD2;
    border-bottom: 1px solid #D9DBD2;
    margin-bottom: 1rem;
    align-items: center;

}

.plant-detail-header {
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    color: #224229;
    font-weight: 500;
    cursor: pointer;
}

.plant-detail-name {
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    color: #224229;
    font-weight: 500;
}

.plant-detail-heading-division {
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    color: #224229;
    font-weight: 500;
}

.plant-detail-bottom {
    display: flex;
    justify-content: space-around;
}

.plant-detail-pic-div {
    width: 40%;
    position: relative;
}

.plant-detail-main-pic {
    overflow: hidden;
}

.img {
    width: 10rem;
    height: 10rem;
}

.plant-detail-main-pic img {
    width: 100%;
}

.plant-detail-info {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}


/* inside the details side of the page */

.plant-detail-subheader {
    display: flex;
    justify-content: space-between;
}

.side-title {
    font-family: "Lustria", serif;
    font-size: 24px;
    font-weight: 400;
    color: #224229;
}

.price {
    font-family: "Lustria", serif;
    font-size: 24px;
    font-weight: 400;
    color: #224229;
}

.plant-detail-description {
    font-family: "Raleway", sans-serif;
    font-size: 15px;
    color: #224229;
}

.plant-detail-pot-colors {
    display: flex;
    gap: 2rem;
    font-size: 12px;
    font-family: "Raleway", sans-serif;
    font-weight: 500;
}


.make-colors-small {
    width: 10rem;
}

.stone {
    width: 42px;
    height: 42px;
    background-color: #E6E8EA;
    border-radius: 35px;
}

.stone:hover {
    cursor: pointer;
    box-shadow: inset 0 0 1px rgba(0, 0, 0, .4), 0 0 0 2px #fcf9f3, 0 0 0 4px #22422957;
}

.plant-detail-stone.selected .stone {
    box-shadow: inset 0 0 1px rgba(0, 0, 0, .4), 0 0 0 2px #fcf9f3, 0 0 0 4px #224229;
}

.charcoal {
    width: 42px;
    height: 42px;
    background-color: #3B4037;
    border-radius: 35px;
}

.charcoal:hover {
    cursor: pointer;
    box-shadow: inset 0 0 1px rgba(0, 0, 0, .4), 0 0 0 2px #fcf9f3, 0 0 0 4px #22422957;
}

.plant-detail-charcoal.selected .charcoal {
    box-shadow: inset 0 0 1px rgba(0, 0, 0, .4), 0 0 0 2px #fcf9f3, 0 0 0 4px #224229;
}


.slate {
    width: 42px;
    height: 42px;
    background-color: #B7C7CD;
    border-radius: 35px;
}

.slate:hover {
    cursor: pointer;
    box-shadow: inset 0 0 1px rgba(0, 0, 0, .4), 0 0 0 2px #fcf9f3, 0 0 0 4px #22422957;
}

.plant-detail-slate.selected .slate {
    box-shadow: inset 0 0 1px rgba(0, 0, 0, .4), 0 0 0 2px #fcf9f3, 0 0 0 4px #224229;
}

.plant-detail-stone {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    gap: .5rem;
}

.plant-detail-charcoal {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    gap: .5rem;
}


.plant-detail-slate {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    gap: .5rem;
}

.plant-detail-buttons {
    display: flex;
    gap: 1rem;
}

.cart-button {
    width: 287px;
    height: 57px;
    border-radius: 35px;
    border: none;
    background-color: #224229;
    color: white;
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    cursor: pointer;

}

.input-cart {
    border-radius: 35px;
    width: 100px;
    height: 57px;
    border: 1px solid #DEE0D7;
    font-family: "Raleway", sans-serif;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.fa-minus {
    font-size: 14px;
    color: #224229;
    cursor: pointer;
}

.fa-plus {
    font-size: 14px;
    color: #224229;
    cursor: pointer;
}

.num {
    padding-bottom: .2rem;
}

.cart-button-login {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 287px;
    height: 57px;
    border-radius: 35px;
    border: none;
    background-color: #224229;
    color: white;
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    cursor: pointer;

}

.plant-detail-pic-div:hover .fa-heart {
    visibility: visible;
}

.pot-colors {
    font-family: "Raleway", sans-serif;
    font-size: 11px;
    font-weight: 500;
    grid-area: color;
    letter-spacing: .1em;
    padding-top: 8px;
    text-transform: uppercase;
    margin-bottom: .5rem;
}

.heart-filled {
    color: #2242299c;
    background-color: #2242299c;
}

@media screen and (min-width: 922px) {
    .wrapper {
        margin-left: calc(2rem + ((100vw - 922px) / 4));
        /* Increase margin-left by 2rem for every 4px increase in screen width */
        margin-right: calc(2rem + ((100vw - 922px) / 4));
    }
}

@media (max-width: 940px) {}

@media screen and (max-width: 840px) {

    .plant-detail-bottom {
        flex-direction: column;
        align-items: center;
        gap: .5rem;
    }

    .input-cart {
        font-size: 15px;
        height: 40px;
    }

    .fa-minus {
        font-size: 10px;
    }

    .fa-plus {
        font-size: 10px;
    }

    .cart-button {
        height: 40px;
    }
}
